:root{
		--poppins:"Poppins", "Roboto", sans-serif;
		--openSans:"Open Sans", "Roboto", sans-serif;
}
a, a:visited, a:hover, a:focus {
	text-decoration: none;
	color: inherit;
}

.MuiFormHelperText-root.Mui-error {
	position: absolute;
	bottom: -20px;
}

body {
	position: relative;
}
